import _ from 'lodash';

export default {
	namespace: true,

	state: {
		uoms: {}
	},

	mutations: {
		SET_ALL_UOMS(state, uoms) {
			state.uoms = uoms;
		}
	},

	actions: {
		setAllUoms(vuexContext, uoms) {
			vuexContext.commit('SET_ALL_UOMS', uoms);
		}
	},

	getters: {
		uoms(state) {
			if (!_.isEmpty(state.uoms)) {
				return state.uoms;
			}
			return {};
		},
	}
}
