import _ from 'lodash';

export default {
	namespace: true,

	state: {
		companies: {},
		connectedCompanies: {},
		currCompany: {}
	},

	mutations: {
		SET_ALL_COMPANIES(state, companies) {
			state.companies = companies;
		},
		SET_CONNECTED_COMPANIES(state, companies) {
			state.connectedCompanies = companies;
		},
		SET_CURR_COMPANY(state, company) {
			state.currCompany = company;
		}
	},

	actions: {
		setAllCompanies(vuexContext, companies) {
			vuexContext.commit('SET_ALL_COMPANIES', companies);
		},
		setAllConnectedCompanies(vuexContext, companies) {
			vuexContext.commit('SET_CONNECTED_COMPANIES', companies);
		},
		updateAllCompanies(vuexContext, newCompanies) {
			let companies = vuexContext.getters.companies;

			_.forEach(newCompanies, (value, key) => {
				companies[key] = value;
			});

			vuexContext.commit('SET_ALL_COMPANIES', companies);
		},
		updateAllConnectedCompanies(vuexContext, newCompanies) {
			let companies = vuexContext.getters.connectedCompanies;

			_.forEach(newCompanies, (value, key) => {
				companies[key] = value;
			});

			vuexContext.commit('SET_CONNECTED_COMPANIES', companies);
		}
	},

	getters: {
		companies(state) {
			if (!_.isEmpty(state.companies)) {
				return state.companies;
			}
			return {};
		},
		connectedCompanies(state) {
			if (!_.isEmpty(state.connectedCompanies)) {
				return state.connectedCompanies;
			}
			return {};
		},
		currCompany(state) {
			if (!_.isEmpty(state.currCompany)) {
				return state.currCompany;
			}
			return {};
		}
	}
}
