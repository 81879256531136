// Util and API
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';
import authApi from '@/api/authApi';
import userSessionApi from '@/api/userSessionApi';

// Others
import config from '@/config/env-constants';
import { firebase } from '@/config/firebase';
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		previousState: {},
		email: null,
		token: null,
		loggedUser: null,
		loggedUserCompany: null
	},

	mutations: {
		SET_STATE(state, previousState) {
			state.previousState = previousState;
		},
		SET_TOKEN(state, token) {
			state.token = token;
		},
		CLEAR_TOKEN(state) {
			state.token = null;
		},
		SET_EMAIL(state, email) {
			state.email = email;
		},
		CLEAR_EMAIL(state) {
			state.email = null;
		},
		SET_LOGGED_USER(state, user) {
			state.loggedUser = user
		},
		CLEAR_LOGGED_USER(state) {
			state.loggedUser = null
		},
		SET_LOGGED_USER_COMPANY(state, company) {
			state.loggedUserCompany = company
		},
		CLEAR_LOGGED_USER_COMPANY(state) {
			state.loggedUserCompany = null
		}
	},

	actions: {
		async authenticateUser(vuexContext, authData) {
			// backup rootState in a variable
			let state = _.cloneDeep(vuexContext.rootState);
			vuexContext.commit('SET_STATE', state);

			const url = await authApi.getFirebaseAuthLink();
			return this._vm.axios.post(url, {
				email: authData.email,
				password: authData.password,
				returnSecureToken: true
			}).then(result => {
				// set the token from the firebase auth result
				const idToken = result.data.idToken
				// set to 15 minutes expiration
				const expirationDate = DateUtil.getCurrentTimestamp() + Number.parseInt(result.data.expiresIn) * 500

				vuexContext.commit("SET_TOKEN", idToken);
				localStorage.setItem("token", idToken);
				localStorage.setItem("tokenExpiration", expirationDate);

				// save the user email as well
				const email = result.data.email
				localStorage.setItem("email", email);

				return result;
			}).catch(err => {
				return err;
			});
		},

		initAuth(vuexContext) {
			let token = localStorage.getItem('token');
			let expirationDate = localStorage.getItem('tokenExpiration');
			let email = localStorage.getItem('email');

			// No Token
			if (!token) {
				return;
			}

			// Expired Token
			if (token != null && DateUtil.getCurrentTimestamp() > Number.parseInt(expirationDate)) {
				vuexContext.dispatch('logout');
				return;
			}

			vuexContext.commit('SET_TOKEN', token);
			vuexContext.commit('SET_EMAIL', email);
		},

		async logUserCredentials(vuexContext, param) {
			// set logged user
			localStorage.setItem("loggedUser", JSON.stringify(param.currUser));
			vuexContext.commit('SET_LOGGED_USER', param.currUser);

			// set logged user company
			localStorage.setItem("loggedUserCompany", JSON.stringify(param.currCompany));
			vuexContext.commit('SET_LOGGED_USER_COMPANY', param.currCompany);

			// mark the login from user session
			await userSessionApi.loginUserSession(param.currUser);
		},

		async logout(vuexContext) {
			try {
				// mark the logout from user session
				let email = localStorage.getItem('email');
				await userSessionApi.logoutUserSession(email);

				// logout from firebase auth
				await firebase.auth().signOut();

				// clear all data from state
				let state = vuexContext.rootState;
				let newState = {};
				Object.keys(state).forEach(key => {
					newState[key] = null;
				});
				vuexContext.rootState = newState;

				// clear all data from local storage
				window.localStorage.clear();
				// clear all data from indexedDB
				window.indexedDB.databases().then((r) => {
					for (const element of r) {
						window.indexedDB.deleteDatabase(element.name);
					}
				});

			} catch (error) {
				return error;
			}
		},
	},

	getters: {
		previousState(state) {
			if (!_.isEmpty(state.previousState)) {
				return state.previousState;
			}
			return {};
		},
		isAuthenticated(state) {
			let token = state.token ? state.token : localStorage.getItem('token');
			return token != null;
		},
		isSuperAdmin(state) {
			let email = state.email ? state.email : localStorage.getItem('email');
			return UserUtil.isSuperAdmin(email);
		},
		isManager(state) {
			let currUser = {};
			if (!_.isEmpty(state.loggedUser)) {
				currUser = state.loggedUser;
			} else {
				let user = localStorage.getItem('loggedUser');
				if (!_.isEmpty(user)) {
					currUser = JSON.parse(user);
				}
			}

			if (!_.isEmpty(currUser)) {
				return currUser.type === config.managerRole;
			}
			return false;
		},
		isSupervisor(state) {
			let currUser = {};
			if (!_.isEmpty(state.loggedUser)) {
				currUser = state.loggedUser;
			} else {
				let user = localStorage.getItem('loggedUser');
				if (!_.isEmpty(user)) {
					currUser = JSON.parse(user);
				}
			}

			if (!_.isEmpty(currUser)) {
				return currUser.type === config.supervisorRole;
			}
			return false;
		},
		isViewer(state) {
			let currUser = {};
			if (!_.isEmpty(state.loggedUser)) {
				currUser = state.loggedUser;
			} else {
				let user = localStorage.getItem('loggedUser');
				if (!_.isEmpty(user)) {
					currUser = JSON.parse(user);
				}
			}

			if (!_.isEmpty(currUser)) {
				return currUser.type === config.viewerRole;
			}
			return false;
		},
		isScanner(state) {
			let currUser = {};
			if (!_.isEmpty(state.loggedUser)) {
				currUser = state.loggedUser;
			} else {
				let user = localStorage.getItem('loggedUser');
				if (!_.isEmpty(user)) {
					currUser = JSON.parse(user);
				}
			}

			if (!_.isEmpty(currUser)) {
				return currUser.type === config.scannerRole;
			}
			return false;
		},
		email(state) {
			if (!_.isEmpty(state.email)) {
				return state.email;
			} else {
				return localStorage.getItem('email');
			}
		},
		token(state) {
			if (!_.isEmpty(state.token)) {
				return state.token;
			} else {
				return localStorage.getItem('token');
			}
		},
		loggedUser(state) {
			if (!_.isEmpty(state.loggedUser)) {
				return state.loggedUser;
			} else {
				let user = localStorage.getItem('loggedUser');
				if (!_.isEmpty(user)) {
					return JSON.parse(user);
				}
				return {};
			}
		},
		loggedUserCompany(state) {
			if (!_.isEmpty(state.loggedUserCompany)) {
				return state.loggedUserCompany;
			} else {
				let company = localStorage.getItem('loggedUserCompany');
				if (!_.isEmpty(company)) {
					return JSON.parse(company);
				}
				return {};
			}
		},
		hasDispatchPermission(state) {
			let currCompany = {};
			if (!_.isEmpty(state.loggedUserCompany)) {
				currCompany = state.loggedUserCompany;
			} else {
				let company = localStorage.getItem('loggedUserCompany');
				if (!_.isEmpty(company)) {
					currCompany = JSON.parse(company);
				}
			}

			if (!_.isEmpty(currCompany)) {
				return currCompany.permissions.dispatch;
			}
			return false;
		},
	}
}
