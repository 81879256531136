import Vuex from 'vuex';
import createPersistedState from "vuex-persist-indexeddb";

// list of modules
import auth from './modules/auth';
import users from './modules/users';
import companies from './modules/companies';
import storageLocations from './modules/storageLocations';
import assetTypeCategories from './modules/assetTypeCategories';
import uoms from './modules/uoms';
import assetTypes from './modules/assetTypes';
import assetTypeCodes from './modules/assetTypeCodes';
import assets from './modules/assets';
import connections from './modules/connections';
import transportations from './modules/transportations';
import dispatches from './modules/dispatches';
import inventorySessions from './modules/inventorySessions';
import stickerGenerator from './modules/stickerGenerator';
import notifications from './modules/notifications';
import assetPoolDistributions from './modules/assetPoolDistributions';
import assetIssuances from './modules/assetIssuances';
import companyAssetPools from './modules/companyAssetPools';
import inquiries from './modules/inquiries';

const createStore = () => {
	return new Vuex.Store({
		modules: {
			auth,
			users,
			companies,
			storageLocations,
			assetTypeCategories,
			uoms,
			assetTypes,
			assetTypeCodes,
			assets,
			connections,
			transportations,
			dispatches,
			inventorySessions,
			stickerGenerator,
			notifications,
			assetPoolDistributions,
			assetIssuances,
			companyAssetPools,
			inquiries
		},
		state: {},
		mutations: {},
		actions: {},
		getters: {},
		plugins: [createPersistedState()]
	});
};

export default createStore;
