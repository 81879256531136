import _ from 'lodash';

export default {
	namespace: true,

	state: {
		assetTypeCodes: {},
		currAssetTypeCode: {},
	},

	mutations: {
		SET_ALL_ASSET_TYPE_CODES(state, assetTypeCodes) {
			state.assetTypeCodes = assetTypeCodes;
		},
		SET_CURR_ASSET_TYPE_CODE(state, assetTypeCode) {
			state.currAssetTypeCode = assetTypeCode;
		}
	},

	actions: {
		setAllAssetTypeCodes(vuexContext, assetTypeCodes) {
			vuexContext.commit('SET_ALL_ASSET_TYPE_CODES', assetTypeCodes);
		},
		updateAllAssetTypeCodes(vuexContext, newAssetTypeCodes) {
			let assetTypeCodes = vuexContext.getters.assetTypeCodes;

			_.forEach(newAssetTypeCodes, (value, key) => {
				assetTypeCodes[key] = value;
			});

			vuexContext.commit('SET_ALL_ASSET_TYPE_CODES', assetTypeCodes);
		}
	},

	getters: {
		assetTypeCodes(state) {
			if (!_.isEmpty(state.assetTypeCodes)) {
				return state.assetTypeCodes;
			}
			return {};
		},
		currAssetTypeCode(state) {
			if (!_.isEmpty(state.currAssetTypeCode)) {
				return state.currAssetTypeCode;
			}
			return {};
		},
	}
}
