export default {
    namespaced: true,
    state: {
        notifications: []
    },
    
    getters: {
        getNotifications: state => state.notifications,
        getUnreadNotificationsLength: state => state.notifications.filter(item => item.isViewed === false).length
    },

    mutations: {
        SET_NOTIFICATIONS(state, payload) {
            state.notifications = payload;
        }
    }
}