import _ from 'lodash';

export default {
	namespace: true,

	state: {
		users: {},
		connectedUsers: {},
		currUser: {},
	},

	mutations: {
		SET_ALL_USERS(state, users) {
			state.users = users;
		},
		SET_CONNECTED_USERS(state, users) {
			state.connectedUsers = users;
		},
		SET_CURR_USER(state, user) {
			state.currUser = user;
		}
	},

	actions: {
		setAllUsers(vuexContext, users) {
			vuexContext.commit('SET_ALL_USERS', users);
		},
		setAllConnectedUsers(vuexContext, users) {
			vuexContext.commit('SET_CONNECTED_USERS', users);
		},
		updateAllUsers(vuexContext, newUsers) {
			let users = vuexContext.getters.users;

			_.forEach(newUsers, (value, key) => {
				users[key] = value;
			});

			vuexContext.commit('SET_ALL_USERS', users);
		},
	},

	getters: {
		users(state) {
			if (!_.isEmpty(state.users)) {
				return state.users;
			}
			return {};
		},
		connectedUsers(state) {
			if (!_.isEmpty(state.connectedUsers)) {
				return state.connectedUsers;
			}
			return {};
		},
		currUser(state) {
			if (!_.isEmpty(state.currUser)) {
				return state.currUser;
			}
			return {};
		}
	}

}

