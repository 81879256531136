export default {
	apiKey: "AIzaSyC-5R128MxqYF9cSvlcJYsaoXtg3onr3ZU",
	authDomain: "ares-prod-app.firebaseapp.com",
	databaseURL: "https://ares-prod-app.firebaseio.com",
	projectId: "ares-prod-app",
	storageBucket: "ares-prod-app.appspot.com",
	messagingSenderId: "1039558533448",
	appId: "1:1039558533448:web:5c839d87a4b141366f937d",
	measurementId: "G-Q99ZJGHQLW"
};
