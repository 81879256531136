// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import { db } from '@/config/firebase';
import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    setUserSessionAttempts(params) {
        let url = `${this.baseUrl}/loginUserSessionAttempts`;
        return axios.post(url, {
            emailAddress: params.emailAddress,
            deviceType: params.deviceType
        });
    },

    async resetAttempts(emailAddress) {
        const userSessionRef = db.collection('userSessions').doc(emailAddress);
        const result = await userSessionRef.set({ attempts: 0 }, { merge: true });
        return result;
    },

    async loginUserSession(currUser) {
        let userSession = {
            attempts: 0,
            companyId: currUser.companyId,
            firstName: currUser.firstName,
            lastName: currUser.lastName,
            emailAddress: currUser.emailAddress,
            deviceType: 'web',
            idToken: localStorage.getItem('token'),
            dateLoggedIn: DateUtil.getCurrentTimestamp(),
            dateLoggedOut: null,
        }
        const dbRef = db.collection('userSessions').doc(currUser.emailAddress);
        return await dbRef.set(userSession, { merge: true });
    },

    async logoutUserSession(emailAddress) {
        let updateFields = {
            attempts: 0,
            dateLoggedOut: DateUtil.getCurrentTimestamp(),
        }
        const dbRef = db.collection('userSessions').doc(emailAddress);
        return await dbRef.set(updateFields, { merge: true });
    },
}