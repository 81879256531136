import axios from 'axios';
import config from '@/config/env-constants';
import { remoteConfig } from '@/config/firebase';


function getProjectId() {
	if (config.currEnv === 'pjl') {
		return `ayun-${config.currEnv}`;
	} else {
		return `ares-${config.currEnv}-app`;
	}
}

export default {

	baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

	async getFirebaseAuthLink() {
		await remoteConfig.fetchAndActivate();
		const firebaseAPIKey = remoteConfig.getString("firebaseAPIKey");
		return config.firebaseAuthLink + firebaseAPIKey;
	},

	async getGoogleMapAPIKey() {
		await remoteConfig.fetchAndActivate();
		return remoteConfig.getString("googleMapAPIKey");
	},

	getUserInitData(userId, view) {
		let requestSource = config.requestSource.CMS;
		const url = `${this.baseUrl}/getUserInitData`;
		return axios.post(url, {
			view: view,
			userId: userId,
			requestSource: requestSource
		});
	}
}
