export default {
    state: {
        currInquiry: {},
    },
    getters: {
        currInquiry: ( state ) => state.currInquiry,
    },
    mutations: {
        SET_CURR_INQUIRY ( state, inquiry ) {
            state.currInquiry = inquiry;
        }
    },
};
