import api from '../../api/stickerApi';

export default {
  namespaced: true,
  state: {
    stickers: [],
    validation_request: false,
    validation_success: '',
    validation_error: '',
    generate_request: false,
    generate_success: '',
    generate_error: '',
    sticker_details: {},
    company_details: {},
    all_stickers: [],
    new_stickers: [],
    reprint_stickers: [],
  },
  getters: {
    getStickers: state => state.stickers,
    getValidationRequestStatus: state => state.validation_request,
    getGenerateRequestStatus: state => state.generate_request,
  },
  mutations: {
    ON_VALIDATION_REQUEST(state) {
      state.validation_request = true;
      state.validation_success = '';
      state.validation_error = '';
    },
    ON_VALIDATION_SUCCESS(state, message) {
      state.validation_request = false;
      state.validation_success = message;
      state.validation_error = '';
    },
    ON_VALIDATION_ERROR(state, message) {
      state.validation_request = false;
      state.validation_success = '';
      state.validation_error = message;
    },
    ON_GENERATE_REQUEST(state) {
      state.generate_request = true;
      state.generate_success = '';
      state.generate_error = '';
    },
    ON_GENERATE_SUCCESS(state, message) {
      state.generate_request = false;
      state.generate_success = message;
      state.generate_error = '';
    },
    ON_GENERATE_ERROR(state, message) {
      state.generate_request = false;
      state.generate_success = '';
      state.generate_error = message;
    },
    FILL_STICKER_DETAILS(state, sticker_details) {
      state.sticker_details = sticker_details;
    },
    FILL_COMPANY_DETAILS(state, company_details) {
      state.company_details = company_details;
    },
    SET_ALL_STICKERS(state, payload) {
      state.all_stickers = payload;
    },
    SET_NEW_STICKERS(state, payload) {
      state.new_stickers = payload;
    },
    SET_REPRINT_STICKERS(state, payload) {
      state.reprint_stickers = payload;
    },
    SET_STICKERS(state, payload) {
      state.stickers = payload;
    }
  },
  actions: {
    async stickerValidation({ rootState, commit }, payload) {
      commit('ON_VALIDATION_REQUEST');

      try {

        let currUserId = rootState.auth.email;
        const { data } = await api.validateStickers(
          { stickerDetails: payload.stickerDetails, type: payload.type },
          currUserId
        );

        commit('SET_ALL_STICKERS', data.stickers.all);
        commit('SET_NEW_STICKERS', data.stickers.new);
        commit('SET_REPRINT_STICKERS', data.stickers.reprint);
        commit('ON_VALIDATION_SUCCESS', 'Validation success!');
        return data;
      } catch (error) {
        commit('ON_VALIDATION_ERROR', error);
        return error;
      }
    },

    async generateStickers({ rootState, commit, state }, payload) {
      commit('ON_GENERATE_REQUEST');

      try {

        let currUserId = rootState.auth.email;
        await api.generateStickers({
          stickers: state.all_stickers,
          newStickers: state.new_stickers,
          reprintStickers: state.reprint_stickers,
          form: state.company_details,
          others: {
            reason: payload.reason
          }
        }, currUserId);

        commit('ON_GENERATE_SUCCESS', 'Stickers has been successfully generated!');
        return true;
      } catch (error) {
        commit('ON_GENERATE_ERROR', error);
        return error;
      }
    }
  }
}