import Vue from 'vue'
import { DateUtil } from '@/utils/dateutil';

Vue.filter('upperCase', function (value) {
  if (!value) return ''
  value = value.toString()
  let str = value.replace('_', ' ')
  return str.charAt(0).toUpperCase() + str.slice(1)
})

Vue.filter('toDateTimeString', function(value) {
  if (!value) return ''
  return DateUtil.getFormattedDateWithTime(value);
});