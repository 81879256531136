import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
  if (config.currEnv === 'pjl') {
      return `ayun-${config.currEnv}`;
  } else {
      return `ares-${config.currEnv}-app`;
  }
}

export default {

  baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

  getStickers(filterBy, view, currUserId) {
    const url = `${this.baseUrl}/getStickers`;
    return axios.post(url, {
      view: view,
      currUserId: currUserId,
      filterBy: JSON.stringify(filterBy)
    });
  },

  validateStickers(payload, currUserId) {
    const url = `${this.baseUrl}/validateStickers`;
    return axios.post(url, {
      currUserId: currUserId,
      type: payload.type,
      ...payload.stickerDetails
    });
  },

  generateStickers(payload, currUserId) {
    const url = `${this.baseUrl}/generateStickers`;
    return axios.post(url, {
      currUserId: currUserId,
      ...payload
    });
  }
}