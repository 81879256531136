
import _ from 'lodash';

export default {
	namespace: true,

	state: {
		dispatches: {},
		currDispatch: {},
		dispatchParams: {}
	},

	mutations: {
		SET_ALL_DISPATCHES(state, dispatches) {
			state.dispatches = dispatches;
		},
		SET_DISPATCH_PARAMS(state, dispatchParams) {
			state.dispatchParams = dispatchParams
		},
		SET_CURR_DISPATCH(state, dispatch) {
			state.currDispatch = dispatch
		}
	},

	actions: {
		setAllDispatches(vuexContext, dispatches) {
			vuexContext.commit('SET_ALL_DISPATCHES', dispatches);
		},

		updateAllDispatches(vuexContext, newDispatches) {
			let dispatches = vuexContext.getters.dispatches;

			_.forEach(newDispatches, (value, key) => {
				dispatches[key] = value;
			});

			vuexContext.commit('SET_ALL_DISPATCHES', dispatches);
		},

		setCurrentDispatch(vuexContext, dispatch) {
			vuexContext.commit('SET_CURR_DISPATCH', dispatch);
		},

		setDispatchParams(vuexContext, params) {
			vuexContext.commit('SET_DISPATCH_PARAMS', params);
		}
	},

	getters: {
		dispatches(state) {
			if (!_.isEmpty(state.dispatches)) {
				return state.dispatches;
			}
			return {};
		},
		dispatchParams(state) {
			if (state.dispatchParams.allCompaniesObj) {
				return state.dispatchParams;
			}
			return {};
		},
		currDispatch(state) {
			if (state.currDispatch.dispatchId) {
				return state.currDispatch;
			}
			return {};
		},
		currDispatchFromPushPull(state) {
			return state.currDispatch;
		}
	}
}
